// @flow
import { motion } from 'framer-motion'
import React from 'react'
import { isMobile } from 'react-device-detect'

import { Icon } from '../../common/components/Icon/Icon'
import styles from './SubjectCarousel.module.scss'
import { Dot } from '../Dot/Dot'
import { SenecaLinkExternal } from '../SenecaLink/SenecaLink'
import { chunkArray, shuffleArray } from '../../common/utils'

type Subjects = {|
  heading: string,
  link: {|
    text: string,
    to: string,
  |},
  ageGroups: {|
    list: {|
      name: string,
    |}[],
  |},
  list: {|
    name: string,
    category: string,
  |}[],
|}

type Props = {|
  subjects: Subjects,
|}

export function SubjectCarousel({
  subjects: { heading, link, ageGroups, list },
}: Props) {
  const chunkedSubjectArray = chunkArray(
    shuffleArray(list),
    Math.ceil(list.length / 2)
  )

  return (
    <div className={styles.subjectCarousel}>
      <h2 className={styles.heading}>{heading}</h2>
      {chunkedSubjectArray.map((chunk, index) => (
        <Tags
          tags={chunk}
          ageGroups={ageGroups.list}
          animationDirection={index === 0 ? 'forwards' : 'reverse'}
          key={index}
        />
      ))}
      <SenecaLinkExternal link={link.to}>{link.text}</SenecaLinkExternal>
    </div>
  )
}

function Tags({
  tags,
  ageGroups,
  animationDirection = 'forwards',
}: {
  tags: {|
    name: string,
    category: string,
  |}[],
  ageGroups: {|
    name: string,
  |}[],
  animationDirection: 'forwards' | 'reverse',
}) {
  return (
    <motion.ul
      initial={{ x: animationDirection === 'forwards' ? '-100vw' : '0' }}
      animate={{
        x: animationDirection === 'forwards' ? '0vw' : '-100vw',
        transition: {
          repeat: Infinity,
          repeatType: 'loop',
          repeatDelay: 0,
          duration: isMobile ? 10 : 30,
          ease: 'linear',
        },
      }}
      className={styles.tagList}
    >
      {[...tags, ...tags].map((tag, index) => {
        return (
          <li className={styles.tag} key={index}>
            <Icon
              name={subjects[tag.category]?.icon ?? subjects.Other.icon}
              color={subjects[tag.category]?.color ?? subjects.Other.color}
            />
            {tag.name} <Dot /> {getExamType(ageGroups)}
          </li>
        )
      })}
    </motion.ul>
  )
}

function getExamType(
  ageGroups: {|
    name: string,
  |}[]
) {
  return ageGroups[Math.floor(Math.random() * ageGroups.length)].name
}

const subjects = {
  Biology: { icon: 'dna', color: '#73bc9e' },
  Business: { icon: 'money', color: '#85934d' },
  Chemistry: { icon: 'flask', color: '#73bc9e' },
  'Combined Science': { icon: 'atom', color: '#73bc9e' },
  'Computer Science': { icon: 'cpu', color: '#8b8a8a' },
  'Design Technology': { icon: 'ruler', color: '#8b8a8a' },
  Economics: { icon: 'money', color: '#a8b27f' },
  English: { icon: 'scroll', color: '#d98cca' },
  'English Language': { icon: 'books', color: '#d98cca' },
  'English Literature': { icon: 'chatCircleText', color: '#d98cca' },
  'Food Preparation and Nutrition': { icon: 'forkKnife', color: '#fcc77c' },
  French: { icon: 'translate', color: '#dd9a42' },
  Geography: { icon: 'globeHemisphereWest', color: '#4bbdbf' },
  German: { icon: 'translate', color: '#dd9a42' },
  History: { icon: 'scroll', color: '#b06d55' },
  Maths: { icon: 'mathOperations', color: '#5966b8' },
  'Media Studies': { icon: 'television', color: '#8b8a8a' },
  Music: { icon: 'musicNotes', color: '#9f7de4' },
  'Non-Verbal Reasoning': { icon: 'strategy', color: '#77f6b7' },
  Other: { icon: 'books', color: '#e3e2e2' },
  'Physical Education': { icon: 'personsArmsSpread', color: '#ae6c7b' },
  Physics: { icon: 'magnet', color: '#73bc9e' },
  'Political Studies': { icon: 'gavel', color: '#82904a' },
  Psychology: { icon: 'brain', color: '#ffa0ef' },
  'Religious Studies': { icon: 'church', color: '#ffa0ef' },
  Science: { icon: 'atom', color: '#73bc9e' },
  Sociology: { icon: 'peoplePair', color: '#ffa0ef' },
  Spanish: { icon: 'translate', color: '#dd9a42' },
  'Verbal Reasoning': { icon: 'chatsCircle', color: '#77f6b7' },
}
